<template>
  <div>Hi {{ $route.params.id }}</div>
</template>

<script>
import WebTorrent from 'webtorrent';

export default {
  name: 'downloader',
  data: function () {
    return {
      client: new WebTorrent()
    }
  },
  mounted() {
    this.client.add(this.$route.params.id, { announce: ['wss://gcp.aashrayarora.com'],
      pieceLength: 256000}, function (torrent) {
      // Torrents can contain many files. Let's use the .mp4 file
      var file = torrent.files.find(function (file) {
        return file.name.endsWith('.png')
      })

      // Display the file by adding it to the DOM.
      // Supports video, audio, image files, and more!
      file.appendTo('body')
    })
  }
}

</script>

<style scoped>

</style>