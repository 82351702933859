<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <div id="dropbox">
      <p>Drag one or more files to this Drop Zone ...</p>
    </div>
    <div id="seeding_torrents">
      <ul>
      <li v-for="(torrent) in torrents" v-bind:key="torrent.infoHash">
        {{ torrent.infoHash }} - Upload speed - {{formatBytes(torrent.uploadSpeed)}} / sec
        - Total Uploaded - {{formatBytes(torrent.uploaded)}} - Total size - {{formatBytes(torrent.length)}}
        - Num Peers - {{torrent.numPeers}}
      </li>
      </ul>
    </div>
  </div>

</template>

<script>

import WebTorrent from 'webtorrent';

const dragDrop = require('drag-drop')

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data: function () {
    return {
      client: new WebTorrent(),
      torrents: [],
      nextTorrentId: 1
    }
  },
  watch: {
    torrents: {
      handler: function () {
        console.log("There is a change in torrents: " + this.torrents.length)
        //console.log(oldTorrents)
        //console.log(newTorrents)
        console.log(this.torrents)
      },
      deep: false,
      immediate: false
    },
    nextTorrentId: {
      handler: function () {
        console.log("There is a change in temp: " + this.nextTorrentId)
        //console.log(oldTorrents)
        //console.log(newTorrents)
      },
      deep: true,
      immediate: false
    }
  },
  methods: {
    formatBytes(bytes,decimals) {
      if(bytes < 1) return parseFloat(bytes) + ' Bytes';
      var k = 1024,
          dm = decimals || 2,
          sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
          i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
  },
  mounted() {
    var _this = this;
    dragDrop('#dropbox', (files, pos, fileList, directories) => {
      console.log('Here are the dropped files', files) // Array of File objects
      console.log('Dropped at coordinates', pos.x, pos.y)
      console.log('Here is the raw FileList object if you need it:', fileList)
      console.log('Here is the list of directories:', directories)
      // `files` is an Array!
      files.forEach(file => {
        console.log(file.name)
        console.log(file.size)
        console.log(file.type)
        console.log(file.lastModifiedDate)
        console.log(file.fullPath) // not real full path due to browser security restrictions
        console.log(file.path) // in Electron, this contains the actual full path
      })
      // This is my ID other's will see on the wire.
      console.log('Created client with ID: ' + this.client.peerId.toString())
      _this.client.seed(files, { announce: ['wss://gcp.aashrayarora.com'],
        pieceLength: 256000}, function (torrent) {
        console.log('Client is seeding ' + torrent.infoHash)
        _this.nextTorrentId++
        _this.torrents.push(torrent)
        //var count = _this.temp++;
        //_this.torrents.splice(count + 1)
        //_this.$set(_this.torrents, count, torrent)
        //_this.temp++
        torrent.on('upload', function (bytes) {
          console.log('just uploaded: ' + bytes)
          console.log('total uploaded: ' + torrent.uploaded)
          console.log('upload speed: ' + torrent.uploadSpeed)
          //console.log('progress: ' + (torrent.progress * 100).toFixed(1) + '%')
        })
        torrent.on('wire', function (wire, addr) {
          // peer ID is a hex.
          // 'addr' does not seem to work when testing locally. Find out more.
          console.log('connected to peer: ' + wire.peerId.toString() + ' via '+ wire.type + ' addr ' + addr)
          console.log('Total peers connected: ' + torrent.numPeers)
        })
      })
      this.client.on('error', function (err) {
        console.log("Client error, " + err + ". cleaning up ...")
      })
      this.client.on('torrent', function (torrent) {
        console.log("Ontorrent ")
        console.log(torrent)
        //if (_this.torrents !== null) {
          //_this.torrents.push(torrent)
        //_this.torrents.push(torrent)
        //_this.temp++
        //} else {
          //_this.torrents = [torrent]
          //_this.torrents = [torrent]
          //_this.temp++
        //}
      })
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
a {
  color: #42b983;
}

#dropbox {
  border: 5px solid red;
}
ul {
  list-style: none;
  padding: 0;
}
li {
  padding: 5px 10px;
  background-color: #EEEEEE;
  border: 1px solid #DDDDDD;
}
</style>
