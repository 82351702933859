import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = true
// make sure to set this synchronously immediately after loading Vue
Vue.config.devtools = true

new Vue({
  render: h => h(App),
  router: router
}).$mount('#app')
