import Vue from 'vue'
import Router from 'vue-router'
import HelloWorld from "@/components/HelloWorld";
import downloader from "@/components/downloader";

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'HelloWorld',
            component: HelloWorld
        },
        {
            path: '/download/:id',
            name: 'downloader',
            component: downloader,
            props: true
        }
    ],
    mode: 'hash'
})